
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

* {
    scrollbar-width: none; /* 针对 Firefox */
    -ms-overflow-style: none; /* 针对 IE 和 Edge */
}

*::-webkit-scrollbar {
    display: none; /* 针对 WebKit 浏览器（如 Chrome, Safari） */
}

body{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    background: #000000;
}


:root {
    --primary-color: rgb(254, 44, 85);
    --primary-color-80: rgba(254, 44, 85, 0.8);
    background: #000000;
}

.ant-layout .ant-layout-sider{
    padding: 0px 8px;
    flex: 0 0 72px;
    background: #fff;
}

.ant-menu-light.ant-menu-root.ant-menu-vertical{
    border: none;
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}

.fadeIn {
    /* animation-name: fadeIn; */
    /* animation: fadeIn .5s ease-in-out; */
}

.animate__fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp
}



@keyframes fadeIn {
    from {
        opacity: .6;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0,30px,0);
        transform: translate3d(0,30px,0)
    }

    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}


@media screen and (max-width: 640px) {
    .mobile_none{
        display: none;
    }

    .login_page{

    }

    .login_page .ant-modal .ant-modal-content{
        background-color: #000 !important;
        box-shadow:none !important;
        height: 97vh;
    }

    .login_page .ant-modal{
        top: 0;
    }

    .login_page .ant-modal .ant-modal-close{
        color: #ffffff;
        width: 50px;
        height: 50px;
        font-size: 30px;
    }
}
