.bookAllButton {
  display: block;
  background-color: var(--primary-color) !important;
  color: #fff !important;
  border: none;
  height: 60px;
  min-width: 100px;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all .3s;
  font-size: 18px;
  &:hover{
    opacity: 0.8;
    font-size: 18px;
  }
}
