
.confirmPage {
  padding: 20px;
  font-family: Arial, sans-serif;
  padding-bottom: 30px;

  .back {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 20px;
    cursor: pointer;
    font-weight: bold;

    img{
      width: 20px;
      height: 20px;
      margin-right: 7px;
    }

    &:hover {
      color: #ffffff;
    }
  }

  .section_title{
    font-weight: bold;
    font-size: 21px;
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: 20px;
    color: #ffffff;
  }

  .flex_wrap{
    display: flex;
    align-items: center;
    .btn{
      margin-left: 10px;
      height: 55px;
    }
  }

  .code_wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr); // 三列等宽
    grid-template-rows: repeat(3, auto);   // 三行，根据内容自动调整行高
    gap: 20px; // 设置网格项之间的间距
    max-width: 600px;
    margin-top: 30px;

    .item {
      color: #ffffff;
      font-size: 18px;
      font-weight: bold;
      padding: 0 20px;
      height: 45px;
      border-radius: 8px;
      line-height: 45px;
      border: 1px solid #dedede;
      text-align: center;
      cursor: pointer;
      transition: all .3s;
      &:hover{
        background: var(--primary-color);
        border: 1px solid #000000;
      }
    }

    .used{
      text-decoration: line-through;
      color: var(--primary-color);
      border: 1px solid rgba(222, 222, 222, 0.7);
      &:hover{
        background: var(--primary-color);
        border: 1px solid #000000;
        color: #ffffff;
      }
    }
  }


  .refer_code_title_wrap{
    span{
      color: var(--primary-color);
      font-weight: bold;
      margin-right: 5px;
    }
    color: #ffffff;
    font-size: 18px;
    margin-bottom: 15px;
  }

  .refresh_wrap{
    position: relative;
    width: min-content;
    img{
      position: absolute;
      bottom: 5px;
      top: 17px;
      right: 10px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .rotate_x {
    animation: rotate 1s linear infinite; /* 设置为无限循环 */
  }

  .rotate_x_stop {
    animation: none; /* 设置为无限循环 */
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .refer_input_wrap{
    color: #ffffff;
    min-width: 200px;
    max-width: 250px;
    background: #00000000;
    border: 1px solid #ffffff !important;
    font-size: 18px;
    height: 55px;
    outline: none !important;
    font-weight: bold;
    border-radius: 10px;
    text-align: center;
    line-height: 55px;
    cursor: pointer;
    &::placeholder{
      color: #dededed1;
      font-size: 16px;
    }
  }

  .hover_text{
    &:hover{
      color: var(--primary-color);
    }
  }

}
@media screen and (max-width: 640px) {
  .confirmPage {
    .confirmationBox{
      margin-left: 0;
      overflow: hidden;
      padding:0;
      border: 1px solid #ffffff;
      display: flex;
      align-items: center;
      flex-direction: column;
      background: #000000;
      .image {
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        border-radius: 10px;
        margin: auto;
        background: #000000;
        img {
          max-width: 100%;
          max-height: 100%;
          border-radius: 10px;
        }
      }

      .details {
        font-size: 18px;
        color: #ffffff;
        margin-bottom: 0;
        margin-left: 5px;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        padding: 16px;
        .item {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          div{
            flex: 1;
            &:nth-child(2){
              text-align: right;
            }
          }
        }

        .label {
          font-weight: bold;
          margin-right: 10px;
        }

        .value {
          font-weight: bold;
          color: var(--primary-color);
          font-size: 20px;
        }

        .buttons {
          display: none;
          justify-content: space-around;
          margin-top: 20px;
          .cancelButton,
          .confirmButton {
            width: 130px;
            border-radius: 10px;
            border: none;
            cursor: pointer;
            color: white;
            transition: all .3s;
            font-weight: bold;
            font-size: 20px;
            height: 45px;
            &.cancelButton {
              background-color: #00000000;
              border: 1px solid var(--primary-color);
              color: var(--primary-color);

              &:hover {
                background-color: var(--primary-color);
                color: #ffffff;
              }
            }

            &.confirmButton {
              background-color: var(--primary-color) !important;
              margin-left: 20px;
              &:hover {
                color: #ffffff !important;
                background-color: var(--primary-color-80) !important;
              }
            }
          }
        }
      }
    }

    .code_wrap {
      display: grid;
      grid-template-columns: repeat(2, 1fr); // 三列等宽
      grid-template-rows: repeat(3, auto);   // 三行，根据内容自动调整行高
      gap: 20px; // 设置网格项之间的间距
      max-width: 600px;
      margin-top: 30px;

      .item {
        color: #ffffff;
        font-size: 18px;
        font-weight: bold;
        padding: 0 20px;
        height: 45px;
        border-radius: 8px;
        line-height: 45px;
        border: 1px solid #dedede;
        text-align: center;
        cursor: pointer;
        transition: all .3s;
        &:hover{
          background: var(--primary-color);
          border: 1px solid #000000;
        }
      }

      .used{
        text-decoration: line-through;
        color: var(--primary-color);
        border: 1px solid rgba(222, 222, 222, 0.7);
        &:hover{
          background: var(--primary-color);
          border: 1px solid #000000;
          color: #ffffff;
        }
      }
    }

    .section_title{
      font-weight: bold;
      font-size: 21px;
      margin-top: 30px;
      margin-bottom: 20px;
      margin-left: 0;
      color: #ffffff;
    }


  }

  .buttons_mobile{
    display: flex;
    flex-direction: column;
  }
}
