// src/components/Card/Card.module.scss
.card {
  background-color: #000000;
  padding: 24px 20px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  color: #fff;
  margin-bottom: 20px;
  border: 2px solid #ffffff;
  .check_wrap{
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    padding: 0;
    object-fit: cover;
    border-radius: 15px;
    border: 2px solid #ffffff;
  }

  .value_wrap{
    position: absolute;
    top: 15px;
    left: 10px;
    font-size: 13px;
    color: #fe2c55;
    font-weight: bold;
  }
}

img {
  width: 100%;
  border-radius: 3px;
}

.price {
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: bold;
  letter-spacing: 1px;
}

.quantityControl {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    padding: 0 10px;
  }

  span {
    font-size: 18px;
  }
}

.bookButton {
  background-color: #ffffff;
  color: #000;
  border: none;
  padding: 10px;
  border-radius: 5px;
  height: 40px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 30px;
  font-weight: bold;
  width: 100%;
  transition: all .3s;
  &:hover{
    background: var(--primary-color) !important;
    color: #fff !important;
  }

  &:disabled{
    background-color: #ffffff;
    color: #000000 !important;
  }
}

.checkIcon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 24px;
  color: #fff;
}

.card_amount{
  font-size: 14px;
  span{
    color: var(--primary-color);
    font-weight: bold;
    font-size: 18px;
    margin-right: 5px;
  }

  div:nth-child(2){
    margin-top: 10px;
  }
}

.row_wrap{
  display: flex;
  flex-direction: column;
  align-items: center;
}


@media screen and (max-width: 640px) {
  .card{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;

    .check_wrap{
      position: absolute;
      top: 7px;
      right: 7px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      padding: 0;
      object-fit: cover;
      border-radius: 15px;
      border: 2px solid #ffffff;
    }
  }

  img {
    width: 150px;
    border-radius: 3px;
  }

  .row_wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .price {
    font-size: 19px;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .bookButton {
    background-color: #ffffff;
    color: #000;
    border: none;
    padding: 10px;
    border-radius: 5px;
    height: 40px;
    cursor: pointer;
    margin-top: 15px;
    font-weight: bold;
    width: 100%;
    transition: all .3s;
    &:hover{
      background: var(--primary-color) !important;
      color: #fff !important;
    }

    &:disabled{
      background-color: #ffffff;
      color: #000000 !important;
    }
  }
}
