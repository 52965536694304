// src/App.module.scss
.app {
  background-color: #111010;
  min-height: 100%;
}

.container {
  padding: 20px;
  padding-right: 100px;
  padding-top: 100px;
}

.bookAllButton {
  display: block;
  margin: 70px auto 0 auto;
  background-color: var(--primary-color) !important;
  color: #fff !important;
  border: none;
  height: 60px;
  width: 200px;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all .3s;
  font-size: 14px;
  &:hover{
    opacity: 0.8;
    font-size: 18px;
    width: 220px;
  }
}

.cardList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}


.confirmPage {
  padding: 20px;
  font-family: Arial, sans-serif;
  padding-bottom: 30px;
  padding-right: 50px;

  .back {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 20px;
    cursor: pointer;
    font-weight: bold;

    img{
      width: 20px;
      height: 20px;
      margin-right: 7px;
    }

    &:hover {
      color: #ffffff;
    }
  }

  .section_title{
    font-weight: bold;
    font-size: 21px;
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: 20px;
    color: #ffffff;
  }

  .check_wrap{
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 16px;
    text-align: center;
    margin-left: 15px;
    position: relative;
    .item{
      height: 40px;
      border: 1px solid #666;
      line-height: 40px;
      padding: 0 20px;
      border-radius: 10px;
      cursor: pointer;
    }
    .act_item{
      height: 40px;
      border: 1px solid var(--primary-color-80);
      line-height: 40px;
      padding: 0 20px;
      border-radius: 10px;
      cursor: pointer;
    }
    .pop_tips{
      position: absolute;
      left: 300px;
      top: 0px;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .input_wrap{
    width: 300px;
    height: 45px;
    font-size: 16px;
    border: 1px solid #fff;
    border-radius: 10px;
    line-height: 45px;
    background: #00000000;
    color: #fff;
    margin-left: 15px;

  }

  input::placeholder{
    color: #666 !important;
  }

  .end_wrap{
    display: flex;
    justify-content: flex-end;

    img{
      width: 30px;
      height: 30px;
      margin-left: 10px;
      cursor: pointer;
      transition: all .3s;
      &:hover{
        scale: 1.1;
      }
    }
  }

  .confirmationBox {
    background-color: #3A3939;
    border-radius: 15px;
    padding: 25px;
    padding-top: 35px;
    max-width: 700px;
    text-align: center;
    margin-left: 30px;
    display: flex;
    .image {
      margin: 0 auto 20px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 10px;
      }
    }

    .details {
      font-size: 16px;
      color: #ffffff;
      margin-bottom: 20px;
      margin-left: 10px;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        div{
          flex: 1;
        }
      }

      .label {
        font-weight: bold;
        margin-right: 10px;
      }

      .value {
        font-weight: bold;
        color: var(--primary-color);
        font-size: 18px;
      }

      .buttons {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        .cancelButton,
        .confirmButton {
          width: 130px;
          border-radius: 10px;
          border: none;
          cursor: pointer;
          color: white;
          transition: all .3s;
          font-weight: bold;
          font-size: 20px;
          height: 45px;
          &.cancelButton {
            background-color: #00000000;
            border: 1px solid var(--primary-color);
            color: var(--primary-color);

            &:hover {
              background-color: var(--primary-color);
              color: #ffffff;
            }
          }

          &.confirmButton {
            background-color: var(--primary-color) !important;
            margin-left: 20px;
            &:hover {
              color: #ffffff !important;
              background-color: var(--primary-color-80) !important;
            }
          }
        }
      }
    }
  }
}

.buttons_mobile {
  display: none;
  justify-content: space-around;
  margin-top: 20px;
  .cancelButton,
  .confirmButton {
    width: 100%;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    color: white;
    transition: all .3s;
    font-weight: bold;
    font-size: 20px;
    height: 60px;
    &.cancelButton {
      background-color: #00000000;
      border: 1px solid var(--primary-color);
      color: var(--primary-color);

      &:hover {
        background-color: var(--primary-color);
        color: #ffffff;
      }
    }

    &.confirmButton {
      background-color: var(--primary-color) !important;
      margin-left: 0;
      margin-top: 20px;
      &:hover {
        color: #ffffff !important;
        background-color: var(--primary-color-80) !important;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .container{
    padding-right: 20px ;
    padding-top: 20px;
    padding-bottom: 100px;
  }

  .bookAllButton{
    width: 100%;
    margin-top: 30px;
  }

  .confirmPage {
    .confirmationBox{
      margin-left: 0;
      overflow: hidden;
      padding:0;
      border: 1px solid #ffffff;
      display: flex;
      align-items: center;
      flex-direction: column;
      background: #000000;
      .image {
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        border-radius: 10px;
        margin: auto;
        background: #000000;
        img {
          max-width: 100%;
          max-height: 100%;
          border-radius: 10px;
        }
      }

      .details {
        font-size: 18px;
        color: #ffffff;
        margin-bottom: 0;
        margin-left: 5px;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        padding: 16px;
        .item {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          div{
            flex: 1;
            &:nth-child(2){
              text-align: right;
            }
          }
        }

        .label {
          font-weight: bold;
          margin-right: 10px;
        }

        .value {
          font-weight: bold;
          color: var(--primary-color);
          font-size: 20px;
        }

        .buttons {
          display: none;
          justify-content: space-around;
          margin-top: 20px;
          .cancelButton,
          .confirmButton {
            width: 130px;
            border-radius: 10px;
            border: none;
            cursor: pointer;
            color: white;
            transition: all .3s;
            font-weight: bold;
            font-size: 20px;
            height: 45px;
            &.cancelButton {
              background-color: #00000000;
              border: 1px solid var(--primary-color);
              color: var(--primary-color);

              &:hover {
                background-color: var(--primary-color);
                color: #ffffff;
              }
            }

            &.confirmButton {
              background-color: var(--primary-color) !important;
              margin-left: 20px;
              &:hover {
                color: #ffffff !important;
                background-color: var(--primary-color-80) !important;
              }
            }
          }
        }
      }
    }



    .section_title{
      font-weight: bold;
      font-size: 21px;
      margin-top: 30px;
      margin-bottom: 20px;
      margin-left: 0;
      color: #ffffff;
    }


  }

  .buttons_mobile{
    display: flex;
    flex-direction: column;
  }
}
