.slider{
  padding: 0px 8px;
  flex: 0 0 72px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 8px;
  border-right: 1px solid rgba(22, 24, 35, 0.12);
  background: #fff;
}


.active_menu{
  color: var(--primary-color);
  font-family: TikTokFont, Arial, Tahoma, PingFangSC, sans-serif;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  height: 40px;
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding: 0 6px;
  svg{
    margin-right: 8px;
  }
  &:hover{
    background: rgba(22, 24, 35, 0.03);
  }
}
