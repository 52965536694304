.popup {
  padding: 20px;
  padding-top: 30px;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  font-family: Arial, sans-serif;

  .header {
    font-weight: bold;
    font-size: 26px;
    align-content: center;
    .icon {
      width: 130px;
      height: 30px;
      margin-bottom: 10px;
      object-fit: contain;
    }
  }

  .sub_header{
    font-weight: bold;
    font-size: 20px;
    span{
      font-size: 35px;
      font-weight: bold;
      zoom: 1.5;
    }
  }

  .step_wrap{
    display: flex;
    align-items: center;
    height: 460px;
    zoom: 1;
    img{
      height: 100%;
      object-fit: contain;
    }
    .des_wrap{
      height: 460px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .des_item{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        text-align: left;
        font-size: 20px;
        font-weight: bold;
        margin: 30px 0;
        div{
          font-size: 15px;
        }
      }
      .des_item:nth-child(2){
        flex: 1;
      }
    }
  }

  .content {
    font-size: 15px;
    margin-bottom: 20px;
    line-height: 1.5;
    text-align: left;
  }

  .button {
    display: none;
    margin-top: 25px;
    background-color: var(--primary-color);
    border: none;
    color: white;
    width: 200px;
    height: 45px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    font-size: 1em;
    transition: all .3s;
    &:hover {
      background-color: var(--primary-color-80);
    }
  }
}


@media screen and (max-width: 640px){
  .popup {
    padding: 0px;
    padding-top: 20px;
    background-color: #fff;
    border-radius: 10px;
    text-align: center;
    font-family: Arial, sans-serif;

    .header {
      font-weight: bold;
      font-size: 26px;
      align-content: center;
      .icon {
        width: 130px;
        height: 30px;
        margin-bottom: 10px;
        object-fit: contain;
      }
    }

    .sub_header{
      font-weight: bold;
      font-size: 18px;
      margin-top: -20px;
      span{
        font-size: 30px;
        font-weight: bold;
        zoom: 1.5;
      }
    }

    .step_wrap{
      display: flex;
      align-items: center;
      height: 460px;
      zoom: 1;
      img{
        height: 100%;
        object-fit: contain;
      }
      .des_wrap{
        height: 460px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .des_item{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          text-align: left;
          font-size: 16px;
          font-weight: bold;
          margin: 15px 0;
          div{
            font-size: 14px;
          }
        }
        .des_item:nth-child(2){
          flex: 1;
        }
      }
    }

    .content {
      font-size: 15px;
      margin-bottom: 20px;
      line-height: 1.5;
      text-align: left;
    }

    .button {
      display: none;
      margin-top: 25px;
      background-color: var(--primary-color);
      border: none;
      color: white;
      width: 200px;
      height: 45px;
      border-radius: 5px;
      font-weight: bold;
      cursor: pointer;
      font-size: 1em;
      transition: all .3s;
      &:hover {
        background-color: var(--primary-color-80);
      }
    }
  }
}
