.referralCodeForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.label {
  font-size: 16px;
  margin-bottom: 10px;
}

.input {
  width: 300px;
  height: 55px;
  padding: 5px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  text-align: center;

  &::placeholder {
    color: rgba(0, 0, 0, 0.1); /* light gray placeholder */
    font-size: 16px;
  }
}

.button_wrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button {
  width: 150px;
  height: 45px;
  background-color: var(--primary-color) !important; /* Purple button */
  color: white !important;
  border: none;
  font-weight: bold;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--primary-color-80) !important; /* Slightly darker purple on hover */
  }
}

.skip_button {
  width: 100px;
  height: 45px;
  font-weight: bold;
  color: #cccccc;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
