// src/components/Header/Header.module.scss
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px;
  height: 60px;
  width: calc(100% + 0px);
  top: 0px;
  position: fixed;
  -webkit-box-align: center;
  align-items: center;
  background: #010101;
  padding: 0 16px;
  z-index: 1;
}

.logo {
  display: flex;
  align-items: center;
  img {
    width: 135px;
    height: 35px;
    object-fit: contain;
    margin-right: 10px;
    margin-bottom: 0;
  }
  span{
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
  }
}

.userInfo {
  display: flex;
  align-items: center;
  color: #ffffff;
  span {
    margin-right: 10px;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
  }

  img {
    width: 40px;
    border-radius: 50%;
  }
}

.pop_content{
  div{
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    transition: all .3s;
    padding: 0 20px;
    font-weight: bold;
    &:hover{
      background: #33333310;
      color: var(--primary-color-80);
    }
  }
}


.bookAllButton {
  display: block;
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  width: 100px;
  cursor: pointer;
}


@media screen and (max-width: 640px) {
  .logo {
    display: flex;
    align-items: center;
    img {
      width: 100px;
      height: 30px;
      object-fit: contain;
      margin-right: 10px;
      margin-bottom: 0;
    }
    span{
      font-size: 18px;
      font-weight: bold;
      line-height: 30px;
    }
  }
}
