.popup {
  padding: 20px;
  padding-top: 30px;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  font-family: Arial, sans-serif;

  .header {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 15px;
    align-content: center;
    .icon {
      width: 130px;
      height: 30px;
      margin-bottom: 10px;
      object-fit: contain;
    }
  }

  .content {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 2;
    text-align: center;
  }

  .button {
    background-color: var(--primary-color);
    border: none;
    color: white;
    width: 200px;
    height: 45px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    font-size: 1em;
    transition: all .3s;
    &:hover {
      background-color: var(--primary-color-80);
    }
  }
}
