// src/components/LoginForm/LoginForm.module.scss
.loginForm {
  background-color: #fff;
  padding: 20px 0px;
  max-width: 400px;
  margin: 0 auto;
}

.logo img {
  width: 30px;
  margin-bottom: 20px;
}

h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

p {
  font-size: 14px;
  color: #555;
  margin-bottom: 20px;
}

.inputGroup {
  margin-bottom: 15px;

  label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    text-align: left;
    font-weight: bold;

    span{
      font-size: 12px;
      font-weight: 400;
      margin-left: 5px;
    }
  }

  .code_wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .send_btn{
      width: 130px;
      height: 50px;
      margin-left: 10px;
      color: #ffffff !important;
      background: var(--primary-color) !important;
      border: none !important;
      font-weight: bold;
    }
  }

  input {
    width: 100%;
    padding: 0 15px;
    height: 50px;
    border: 1px solid #ccc!important;
    border-radius: 5px;
    font-size: 16px;
  }
}

.inputGroupWithButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  .inputGroup {
    flex: 1;
    margin-right: 10px;
  }
}

.captchaButton {
  padding: 10px 15px;
  background-color: #533b92;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 11px;
}

.forgotPassword {
  text-align: right;
  margin-bottom: 20px;

  a {
    font-size: 14px;
    color:var(--primary-color);
    text-decoration: none;
  }
}

.loginButton {
  width: 100%;
  padding: 10px;
  background-color: var(--primary-color);
  font-weight: bold;
  color: #fff;
  border: none;
  height: 50px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  transition: all .3s;
  &:hover{
    font-size: 20px;
    color: #ffffff !important;
    background-color: var(--primary-color-80)!important;
  }
}

.signupLink {
  margin-top: 20px;

  p {
    font-size: 14px;
    color: #555;

    a {
      color: var(--primary-color);
      text-decoration: none;
    }
  }
}


@media screen and (max-width: 640px) {
  .loginForm{
    background: #000000;
    color: #ffffff;
  }


  p {
    font-size: 16px !important;
    color: rgba(255, 255, 255, 0.9) !important;
    margin-bottom: 20px;
  }

  .inputGroup {
    margin-bottom: 15px;

    label {
      display: block;
      font-size: 16px;
      margin-bottom: 5px;
      text-align: left;
      font-weight: bold;
      color: #ffffff;
      span{
        font-size: 12px;
        font-weight: 400;
        margin-left: 5px;
      }
    }

    .code_wrap{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .send_btn{
        width: 130px;
        height: 50px;
        margin-left: 10px;
        color: #ffffff !important;
        background: var(--primary-color) !important;
        border: none !important;
        font-weight: bold;
      }
    }

    input {
      width: 100%;
      padding: 0 15px;
      height: 55px;
      font-weight: bold;
      border: 1px solid #ccc!important;
      border-radius: 5px;
      font-size: 17px;
    }
  }

  .login_page{
    :global{
      .ant-modal .ant-modal-content{
        background-color: #000 !important;
        box-shadow:none !important;
        height: 95vh;
      }

      .ant-modal{
        top: 0;
      }

      .ant-modal .ant-modal-close{
        color: #ffffff;
        width: 50px;
        height: 50px;
        font-size: 30px;
      }
    }
  }
}
